module.exports = {
  comm: {
    home: 'ホーム',
    pricing: '料金',
    blog: '音声クローニングとAPI', // 更新
    language: '言語:',
    select_language: '言語を選択',
    txt2voice: 'テキストから音声へ',
    voice2txt: '音声からテキストへ',
    voiceclone: '音声のクローン化',
    video2txt: 'ビデオからテキストへ',
    footer_help: 'サポートを探していますか？メールまでお問い合わせください:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'ログイン',
    login_desc: 'TikTok音声ジェネレーターアカウントにアクセスするにはログインしてください',
    logout: 'ログアウト',
  },
  txt2voice: {
    title: 'TikTok音声を無料で作成: 面白いTikTok音声で読み上げ',
    description: 'ジェシーの声、C3POの声、ゴーストフェイスの声などの面白いTikTok音声を無料で合成してダウンロード',
    keywords: 'TikTok音声ツール,TikTokAI音声, TikTok音声ツール',
    main_title_p01: 'TikTok音声ツール - 最高のTikTok AI音声',
    main_title_p02: '面白いTikTok音声を合成：ジェシーの声、C3POの声、ゴーストフェイスの声など',
    main_textarea_holder: 'ここにテキストを入力または貼り付けてください',
    main_genvoice: '合成',
    main_generating: '合成中',
    main_input_empty: 'テキストを入力または貼り付けてください',
    daily_usage_limit_msg: '1) 本日の使用回数の上限に達しました。明日再度お試しください。2) 緊急で使用したい場合はメールまでお問い合わせください。',
    text_max_prompt1: '単語数を制限してください：',
    text_max_prompt2: '単語以下にしてください！',
    popup_nologin_title: 'サブスクリプションプランをアップグレード',
    popup_nologin_desc: 'プレミアムサブスクリプションにアップグレードして、より多くの音声生成時間をアンロックしてください。',
    popup_nologin_btntext: 'サブスクリプションプランを探す',
    popup_nosub_title: 'サブスクリプションプランをアップグレード',
    popup_nosub_desc: 'プレミアムサブスクリプションにアップグレードして、より多くの音声生成時間をアンロックしてください。',
    popup_nosub_btntext: 'サブスクリプションプランを探す',
    popup_sublimit_title: 'サブスクリプションが使い果たされました。アップグレードしてください',
    popup_sublimit_desc: '当社のプレミアム機能と無制限のアクセスで体験を向上させてください。',
    popup_sublimit_btntext: '追加購入へ',
  },
  pricing: {
    new_features_alert: "📣 有料会員向けに🎙️音声クローニングと🤖APIサービスを開始しました！（お支払い後、これらの機能についてのサポートはtiktokaivoicetool@gmail.comまでメールでお問い合わせください）。また、カスタマイズのご要望がありましたら、お気軽にメールでお問い合わせください😃", // 新增
    pricing_title: '個人クリエイターから大企業まで、あなたに合ったプランがあります。',
    pricing_desc: '100以上の自然で人間らしい声。15以上の言語。そしてほぼ誰にとっても最低価格',
    sub_free_name: '無料',
    sub_free_desc: '最先端のAIオーディオを試してみたい個人向け',
    sub_free_content: [
      '月1000文字制限',
      '1日5回の生成',
    ],
    sub_starter_name: 'スターター',
    sub_starter_desc: 'グローバルな視聴者向けにプレミアムコンテンツを作成するクリエイター向け',
    sub_starter_content: [
      '1つの音声クローン',
      '月間300,000文字制限（約7時間の音声）',
      '無制限ダウンロード',
      '72時間以内のメールサポート',
      '新しい音声と機能への優先アクセス',
    ],
    sub_pro_name: 'プロ',
    sub_pro_desc: 'コンテンツ制作を拡大するクリエイター向け',
    sub_pro_content: [
      'APIアクセス',
      '3つの音声クローン',
      '月間1,000,000文字制限（約24時間の音声）',
      '無制限ダウンロード',
      '48時間以内のメールサポート',
      '新しい音声と機能への優先アクセス',
    ],
    period_year: '年払い',
    period_month: '月払い',
    period_month_short: '月',
    billed_year: '年間請求',
    most_popular: '最も人気',
    discount_quantity: '48ドル節約',
    buy_btntext: '始める',
    pay_succ: '支払い成功！',
    pay_succ_desc: 'ご購入ありがとうございます。取引は正常に完了しました。',
    pay_succ_btntext: 'TikTok音声の使用を開始',
    pay_fail: '支払い失敗！',
    pay_fail_desc: "申し訳ありませんが、取引を処理できませんでした。もう一度お試しいただくか、サポートチーム(tiktokaivoicetool@gmail.com)にお問い合わせください。",
    pay_fail_btntext: 'ホームページに戻る',
  },
  setting: {
    setting: '設定',
    setting_title: 'ここでアカウント、使用状況、サブスクリプションを管理します。',
    basic_info: '基本情報', // 新增，放在 setting_title 下方
    user_name: '名前',
    user_email: 'メール',
    user_account: 'アカウント',
    user_subscript: '現在のプラン',
    user_usage: '使用状況',
    manage_subscript: 'サブスクリプション管理',
    use_limit_promote: "月間10000文字制限のうち5000文字を使用しました。",
    not_logged_in: 'ログインしていません', // 新增
    go_to_login: 'ログインへ進む >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "TikTok音声ジェネレーター",
    subtitle: "TikTok音声ジェネレーターアカウントにアクセスするにはログインしてください",
    alreadyLoggedIn: "すでにログインしています",
    goToHome: "ホームページへ移動"
  },
  faq: {
    quest1: 'TikTok音声ツール - 最高のTikTok AI音声ツール',
    answer1_1: 'これは、最新のTikTok TTS技術で開発されたTikTok AI音声ツール で、ジェシーの声（女性の声）、シリの声、ゴーストフェイスの声、C3POの声（ロボットの声）、ディープボイス（物語の語り手）、スカイの声、温かい声、ベスティの声、ヒーローの声（クリスの声）、共感的な声、真剣な声、ジョーイの声、スティッチの声、ストームトルーパーの声（スター・ウォーズ）、ロケットの声（ガーディアンズ・オブ・ギャラクシー）などの音声を合成できます。',
    answer1_2: '近日中にサポートされるTikTok AI音声には、デレクの声、トリックスターの声、オースティン・バトラーの声、ニュースレポーターの声、アダムの声、犬の声、マイリー・サイラスの声、アレクサの声、エイリアンの声、動物の声、赤ちゃんの声、コンピュータの声、チップマンクの声、エコーの声、NPRの声などがあります。',
    answer1_3: 'さらに、このTikTok AI音声ツール は中国語、日本語、韓国語、ベトナム語、タイ語、ヒンディー語、ペルシャ語、ロシア語、ドイツ語、フランス語、ルーマニア語、チェコ語、スペイン語、ポルトガル語、ベンガル語、イタリア語、アラビア語、ウルドゥー語、繁体字中国語、マレー語など、複数の言語に対応しています。',
    answer1_4: '特定の音声が緊急に合成したい場合は、メールまでお問い合わせください。',
    quest2: 'TikTok音声ツールの利点は何ですか？',
    answer2_1: '- TikTok音声ツールは、TikTok動画でよく使用されるさまざまなタイプの音声を合成できます。',
    answer2_2: '- これは、人間のようなTikTok AI音声を合成できる最新のAI音声ツールです。',
    answer2_3: '- PCでのビデオ編集に便利です。',
    answer2_4: '- TikTokアプリのツールでは見つけられない音声を使用できます。',
    quest3: 'TikTok音声ツールの使用方法は？',
    answer3_1: 'TikTok音声ツールは非常に簡単に使用できます：',
    answer3_2: '- 言語と音声のアクセントを選択します。',
    answer3_3: '- 音声に変換するテキストをテキストボックスに入力します。',
    answer3_4: '- 合成ボタンを押して数秒待ちます。',
    answer3_5: '- TikTok AI音声を再生またはダウンロードします。',
    quest4: '最も有名で面白いTikTok音声は何ですか？',
    answer4_1: '最も有名な男性のTikTok音声は、ディープ音声で、正式にはストーリーテラーと呼ばれています。',
    answer4_2: '最も面白いTikTok音声として、個人的にはゴーストフェイスの声とC3POの声をお勧めします。これらは非常に認識しやすく、視聴者をすぐに引き込むため、TikTokの面白いビデオの吹き替えによく使用されます。',
    quest5: '最もイライラするTikTok音声は何ですか？',
    answer5_1: '本当に「イライラする」TikTok音声はないと思います。',
    answer5_2: 'それは、音声が使用されるコンテキストと内容に関係していることが多いです。例えば、ジェシーの声を煩わしいと感じる人もいるかもしれませんが、それはTikTokである期間頻繁に登場したためです。しかし、その音色と品質から、実際には素晴らしいTikTok音声効果です。また、この音声の裏にいるTikTokの声優は非常にプロフェッショナルで、自身もTikTokに動画を投稿しています。',
    answer5_3: 'したがって、皆さんにはこのツールの音声を何度も聴き、頑張って自分に最適なTikTok音声効果を見つけることをお勧めします。',
    quest6: 'TikTok音声ツールでジェシーの声を合成するには？',
    answer6_1: 'TikTok音声ツールの最初のドロップダウンメニューで「英語（米国）」アクセントを選択し、次に他のドロップダウンメニューで「ジェシーの声（女性の声）」を選択します。',
    answer6_2: 'テキストを入力して生成ボタンをクリックします。',
    answer6_3: '約10秒後、AI音声が聞こえます。操作バーで下向きの矢印があるボタンを見つけ、クリックしてダウンロードします。',
    quest7: 'TikTok音声ツールでC3POの声を合成するには？',
    answer7_1: '英語（米国）アクセントを選択し、次にC3POの声（ロボットの声）を選択します。',
    answer7_2: 'テキストを入力して合成ボタンをクリックします。',
    answer7_3: 'しばらく待つと、AI音声が聞こえます。操作バーで下向きの矢印があるボタンを見つけ、クリックしてダウンロードします。',
    quest8: 'TikTok音声ツールでゴーストフェイスの声を生成するには？',
    answer8_1: '英語（米国）を選択し、次にゴーストフェイスの声を選択します。',
    answer8_2: 'テキストを入力または貼り付け、合成ボタンを押します。',
    answer8_3: 'TikTok AI音声が生成され、再生できます。',
    quest9: 'TikTok音声ツールでシリの声を生成するには？',
    answer9_1: '英語（米国）を選択し、シリの声をクリックします。',
    answer9_2: 'テキストを入力して合成ボタンを押します。',
    answer9_3: 'その後、AI音声が生成されます。',
    quest10: 'TikTokビデオにTikTok音声効果を追加するには？',
    answer10_1: 'TikTokの公音声をTikTokで使用する方法について知りたい場合、このトピックに関する専用ブログに投稿する予定です。',
    answer10_2: 'ここでは、TikTok音声ツールで合成してダウンロードした音声をTikTokに公開する方法を説明します。',
    answer10_3: '1. PCでTikTok音声を合成し、それをTikTokや他のビデオ編集アプリにアップロードしたい場合は、音声ファイルをスマートフォンに転送する必要があります。iPhoneの場合、AirDropを使用して転送できます。Androidの場合、あまり詳しくありませんが、必ず方法やツールを見つけることができます。',
    answer10_4: '2. 音声ファイルがスマートフォンに転送されたら、TikTokを開きます。',
    answer10_5: "- 画面の下部にある「+」ボタンをタップし、スマートフォンにあるビデオを選択します。",
    answer10_6: '- ビデオをアップロードしたら、画面右側にある四角いアイコンを見つけて編集ページに入ります。',
    answer10_7: "- ページの下部にある「音声を追加」ボタンを見つけ、先ほど転送した音声ファイルを選択します。",
    answer10_8: '- このインターフェースでビデオにいくつかの調整を行い、右上のボタンをクリックして公開します。',
    quest11: 'TikTok音声は無料ですか？',
    answer11: 'はい、それは無料のTikTok AI音声テキストから音声への変換ツールです。',
    quest12: 'TikTokビデオに人気のTikTok音声を合成するにはどこに行けばいいですか？',
    answer12: 'https://tiktokvoice.net/ を訪れて、TikTok AI音声をビデオに使用してください。'
  },
  host: 'https://tiktokvoice.net'
}